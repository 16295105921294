'use strict';

var abs = require('./abs');

var floor = require('./floor');

var ToNumber = require('./ToNumber');

var $isNaN = require('../helpers/isNaN');

var $isFinite = require('../helpers/isFinite');

var $sign = require('../helpers/sign'); // https://262.ecma-international.org/12.0/#sec-tointegerorinfinity


module.exports = function ToIntegerOrInfinity(value) {
  var number = ToNumber(value);

  if ($isNaN(number) || number === 0) {
    return 0;
  }

  if (!$isFinite(number)) {
    return number;
  }

  return $sign(number) * floor(abs(number));
};
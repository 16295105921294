'use strict';

module.exports = function fromPropertyDescriptor(Desc) {
  if (typeof Desc === 'undefined') {
    return Desc;
  }

  var obj = {};

  if ('[[Value]]' in Desc) {
    obj.value = Desc['[[Value]]'];
  }

  if ('[[Writable]]' in Desc) {
    obj.writable = !!Desc['[[Writable]]'];
  }

  if ('[[Get]]' in Desc) {
    obj.get = Desc['[[Get]]'];
  }

  if ('[[Set]]' in Desc) {
    obj.set = Desc['[[Set]]'];
  }

  if ('[[Enumerable]]' in Desc) {
    obj.enumerable = !!Desc['[[Enumerable]]'];
  }

  if ('[[Configurable]]' in Desc) {
    obj.configurable = !!Desc['[[Configurable]]'];
  }

  return obj;
};
'use strict';

var assertRecord = require('../helpers/assertRecord');

var fromPropertyDescriptor = require('../helpers/fromPropertyDescriptor');

var Type = require('./Type'); // https://ecma-international.org/ecma-262/6.0/#sec-frompropertydescriptor


module.exports = function FromPropertyDescriptor(Desc) {
  if (typeof Desc !== 'undefined') {
    assertRecord(Type, 'Property Descriptor', 'Desc', Desc);
  }

  return fromPropertyDescriptor(Desc);
};
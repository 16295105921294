
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { themes } from 'mdx-deck'
import { CodeSurfer } from 'code-surfer'
export const theme = themes.future;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Steps = makeShortcode("Steps");
const Notes = makeShortcode("Notes");
const Image = makeShortcode("Image");
const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Welcome!`}</h1>
    <p>{`Sit back, get an afternoon coffee ☕`}</p>
    <hr></hr>
    <h2>{`Hello!`}</h2>
    <Steps mdxType="Steps">
      <p>{`I'm Matei.`}</p>
      <p>{`I really like computers.`}</p>
      <p>{`Full-stack developer.`}</p>
      <p><strong parentName="p">{`Way`}</strong>{` too much experience with DevOps.`}</p>
    </Steps>
    <Notes mdxType="Notes">
      <p>{`Hello, everyone! I'm Matei, I'm a 4th year computer science major in Warren College.`}</p>
      <p>{`I really like computers. I've liked them since I was like 5 apparently, according to my parents, and I've always loved tinkering with them.
Since then, I've done less of "messing around randomly with system settings" and more actual coding.`}</p>
      <p>{`I like to consider myself a generalist in computer science, and I've tried to strive for that by generally just learning as much as I can
at every layer of computers, from physics and transistors to JavaScripts frameworks, which are, probably like, 600 layers of abstraction above
transistors.`}</p>
      <p>{`I will concede that I've spent way more time on DevOps than anyone who considers themselves a generalist should, despite the fact that "DevOps"
as a term implies generalism. I've deployed computer infrastructure in pretty much every way, from bare-metal servers to Kubernetes clusters of
various sizes.`}</p>
    </Notes>
    <hr></hr>
    <h2>{`Quick disclaimers`}</h2>
    <Steps mdxType="Steps">
      <p>{`I talk a lot.`}</p>
      <p>{`Don't hesitate to interrupt and ask questions!`}</p>
      <p>{`✋ on Zoom.`}</p>
    </Steps>
    <hr></hr>
    <h2>{`TL;DR for talk`}</h2>
    <Steps mdxType="Steps">
      <p>{`A quick tutorial on containers.`}</p>
      <p>{`Why would we want to use them?`}</p>
      <p>{`Quick demo using Slurm and HPCG.`}</p>
    </Steps>
    <Notes mdxType="Notes">
      <p>{`Alright, so what am I actually presenting for you today?`}</p>
      <p>{`Well, I'm going to give y'all a quick small tutorial on containers, what they are, how to make them, and maybe some more in-depth technical details if anyone is interested.`}</p>
      <p>{`We'll also talk about why we would want to use them for this competition in particular, and just some other general usecases.`}</p>
      <p>{`We'll do this workshop of sorts using a quick demo on Slurm and HPCG. The idea is that we want to use container workflows to make a Slurm cluster and install programs on it
that we'll use for running our cluster, such as the HPCG benchmark.`}</p>
    </Notes>
    <hr></hr>
    <h2>{`What are containers?`}</h2>
    <Steps mdxType="Steps">
      <p>{`A special kind of virtual machine.`}</p>
      <p>{`The idea is simple: "why duplicate the kernel?"`}</p>
      <p>{`Re-use kernel across every "virtual machine".`}</p>
    </Steps>
    <Notes mdxType="Notes">
      <ul>
        <li parentName="ul">{`Cover the basic difference between a VM and a container.`}</li>
      </ul>
      <p>{`Maybe even annotate the famous graphic using Zoom.`}</p>
    </Notes>
    <hr></hr>
    <h2>{`Why containers?`}</h2>
    <Steps mdxType="Steps">
      <p>{`Keeps things the same everywhere.`}</p>
      <p>{`Installing applications is easy and isolated!`}</p>
      <p>{`No need to futz around with configurations.`}</p>
    </Steps>
    <Notes mdxType="Notes">
      <p>{`Give anecdotes on why it's important to keep things the same anywhere. Configuration management like Ansible and stuff maybe.`}</p>
      <p>{`Installing applications is as easy as running a container, especially for environments that are highly volatile.`}</p>
      <p>{`You may not futz with configurations but you will futz around with environment variables, but it's still somewhat easier overall.
Envvars are easier to set than configuration files.`}</p>
    </Notes>
    <hr></hr>
    <h2>{`"Oh, you mean Docker!"`}</h2>
    <Steps mdxType="Steps">
      <p>{`I mean OCI containers.`}</p>
      <p>{`They can be run by different kinds of container daemons.`}</p>
      <p>{`Podman, Docker, etc.`}</p>
    </Steps>
    <hr></hr>
    <h2>{`Singularity`}</h2>
    <Image src='http://sylabs.io/wp-content/uploads/2022/03/singularity-logo-round.svg' style={{
      width: '30vh',
      height: '30vh'
    }} mdxType="Image">
    </Image>
    <p>{`Another container platform designed for HPC.`}</p>
    <hr></hr>
    <h2>{`Singularity vs Docker`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Has few security restrictions`}</li>
        <li parentName="ul">{`Higher performance!`}</li>
        <li parentName="ul">{`Works with Docker containers!`}</li>
        <li parentName="ul">{`Less isolated features`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`How to Singularity?`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Install Singularity on host machine`}</li>
        <li parentName="ul">{`Download Singularity container (or build a .sif)`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`$ singularity run random-container.sif`}</inlineCode></li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`How could we use it?`}</h2>
    <Steps mdxType="Steps">
      <p>{`Standard platform for all our needs in the competition.`}</p>
      <p>{`Setup Slurm, dependencies for projects, etc.`}</p>
      <p>{`Environment consistent across all nodes, no need to reinstall.`}</p>
    </Steps>
    <hr></hr>
    <h2>{`Starting Simple`}</h2>
    <p>{`I'll show you an example definition file for Singularity.`}</p>
    <p>{`We'll then install Slurm, dependencies, etc.`}</p>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Base information\" subtitle=\"What container to start from, stage, etc.\"",
          "title": "\"Base",
          "information\"": true,
          "subtitle": "\"What",
          "container": true,
          "to": true,
          "start": true,
          "from,": true,
          "stage,": true,
          "etc.\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: final
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Setup section\" subtitle=\"Stuff that gets run on host machine. Be careful!\"",
          "title": "\"Setup",
          "section\"": true,
          "subtitle": "\"Stuff",
          "that": true,
          "gets": true,
          "run": true,
          "on": true,
          "host": true,
          "machine.": true,
          "Be": true,
          "careful!\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Files section\" subtitle=\"What files to copy from host machine to container\"",
          "title": "\"Files",
          "section\"": true,
          "subtitle": "\"What",
          "files": true,
          "to": true,
          "copy": true,
          "from": true,
          "host": true,
          "machine": true,
          "container\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Environment section\" subtitle=\"Environment variables everything uses\"",
          "title": "\"Environment",
          "section\"": true,
          "subtitle": "\"Environment",
          "variables": true,
          "everything": true,
          "uses\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt

%environment
    export SOME_VAR=example
    export LC_ALL=C
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Post section\" subtitle=\"Where most of the magic happens\"",
          "title": "\"Post",
          "section\"": true,
          "subtitle": "\"Where",
          "most": true,
          "of": true,
          "the": true,
          "magic": true,
          "happens\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt

%environment
    export SOME_VAR=example
    export LC_ALL=C

%post
    apt update && apt install -y netcat slurmd slurmctld
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Run section\" subtitle=\"What happens when you type 'singularity run'. Typically just 'run bash'.\"",
          "title": "\"Run",
          "section\"": true,
          "subtitle": "\"What",
          "happens": true,
          "when": true,
          "you": true,
          "type": true,
          "'singularity": true,
          "run'.": true,
          "Typically": true,
          "just": true,
          "'run": true,
          "bash'.\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt

%environment
    export SOME_VAR=example
    export LC_ALL=C

%post
    apt update && apt install -y netcat slurmd slurmctld

%runscript
    exec "$@"
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Start section\" subtitle=\"What happens when the container start, no matter how.\"",
          "title": "\"Start",
          "section\"": true,
          "subtitle": "\"What",
          "happens": true,
          "when": true,
          "the": true,
          "container": true,
          "start,": true,
          "no": true,
          "matter": true,
          "how.\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt

%environment
    export SOME_VAR=example
    export LC_ALL=C

%post
    apt update && apt install -y netcat slurmd slurmctld

%runscript
    exec "$@"

%startscript
    nc -lp 8080
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Test section\" subtitle=\"Run after container is created. If these fail, build fails.\"",
          "title": "\"Test",
          "section\"": true,
          "subtitle": "\"Run",
          "after": true,
          "container": true,
          "is": true,
          "created.": true,
          "If": true,
          "these": true,
          "fail,": true,
          "build": true,
          "fails.\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt

%environment
    export SOME_VAR=example
    export LC_ALL=C

%post
    apt update && apt install -y netcat slurmd slurmctld

%runscript
    exec "$@"

%startscript
    nc -lp 8080

%test
    grep -q NAME=\\"Ubuntu\\" /etc/os-release
    if [ $? -eq 0 ]; then
        echo "Container base is Ubuntu as expected."
    else
        echo "Container base is not Ubuntu."
    fi
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Label section\" subtitle=\"Don't forget to sign your work! :D\"",
          "title": "\"Label",
          "section\"": true,
          "subtitle": "\"Don't",
          "forget": true,
          "to": true,
          "sign": true,
          "your": true,
          "work!": true,
          ":D\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt

%environment
    export SOME_VAR=example
    export LC_ALL=C

%post
    apt update && apt install -y netcat slurmd slurmctld

%runscript
    exec "$@"

%startscript
    nc -lp 8080

%test
    grep -q NAME=\\"Ubuntu\\" /etc/os-release
    if [ $? -eq 0 ]; then
        echo "Container base is Ubuntu as expected."
    else
        echo "Container base is not Ubuntu."
    fi

%labels
    Author Absolutely Not My Work (thanks Singularity website)
    Version v0.0.1

`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h2>{`Next Steps`}</h2>
    <p>{`Let's get Slurm properly set up.`}</p>
    <p>{`We need to configure our cluster first.`}</p>
    <p><a parentName="p" {...{
        "href": "https://slurm.schedmd.com/configurator.html"
      }}>{`Thank God for the Internet.`}</a></p>
    <hr></hr>
    <h2>{`Let's get it installed!`}</h2>
    <p>{`Now we just need to:`}</p>
    <ul>
      <li parentName="ul">{`add the configuration file to the container`}</li>
      <li parentName="ul">{`enable the daemon service on the worker nodes`}</li>
      <li parentName="ul">{`enable the controller service on the controller nodes`}</li>
    </ul>
    <p>{`Sadly, the easiest way to separate is just two separate containers.`}</p>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt

%environment
    export SOME_VAR=example
    export LC_ALL=C

%post
    apt update && apt install -y munge netcat slurmd slurmctld
    apt install sudo -y && \\
    apt install python3.9 python3-pip -y && \\
    useradd -m admin -s /usr/bin/bash -d /home/admin && \\
    echo "admin:admin" | chpasswd && \\
    adduser admin sudo && \\
    echo "admin     ALL=(ALL) NOPASSWD:ALL" >> /etc/sudoers

%runscript
    exec "$@"

%startscript
    nc -lp 8080

%test
    grep -q NAME=\\"Ubuntu\\" /etc/os-release
    if [ $? -eq 0 ]; then
        echo "Container base is Ubuntu as expected."
    else
        echo "Container base is not Ubuntu."
    fi

%labels
    Author Absolutely Not My Work (thanks Singularity website)
    Version v0.0.1

`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Add our configuration file\" subtitle=\"Make sure we also start the Slurm services.\"",
          "title": "\"Add",
          "our": true,
          "configuration": true,
          "file\"": true,
          "subtitle": "\"Make",
          "sure": true,
          "we": true,
          "also": true,
          "start": true,
          "the": true,
          "Slurm": true,
          "services.\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt
    /home/ghost/Downloads/slurm.conf /etc/slurm-llnl/
    /home/ghost/Downloads/cgroup.conf /etc/slurm-llnl/

%environment
    export SOME_VAR=example
    export LC_ALL=C

%post
    apt update && apt install -y munge netcat slurmd slurmctld
    apt install sudo -y && \\
    apt install python3.9 python3-pip -y && \\
    useradd -m admin -s /usr/bin/bash -d /home/admin && \\
    echo "admin:admin" | chpasswd && \\
    adduser admin sudo && \\
    echo "admin     ALL=(ALL) NOPASSWD:ALL" >> /etc/sudoers

%startscript
    sudo sed -i "s/REPLACE_IT/CPUs=$(nproc)/g" /etc/slurm-llnl/slurm.conf
    sudo service munge start
    sudo service slurmctld start # Use ONLY this if controller node
    # sudo slurmd -N $(hostname) # Use ONLY this if worker node

%runscript
    exec "$@"

%test
    grep -q NAME=\\"Ubuntu\\" /etc/os-release
    if [ $? -eq 0 ]; then
        echo "Container base is Ubuntu as expected."
    else
        echo "Container base is not Ubuntu."
    fi

%labels
    Author Absolutely Not My Work (thanks Singularity website)
    Version v0.0.1

`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`So far, so good!`}</h1>
    <Steps mdxType="Steps">
      <p>{`HPCG is up next.`}</p>
      <p>{`It would be nice not to leave compilation in container.`}</p>
      <p>{`Keeping containers clean and lean is good practice.`}</p>
    </Steps>
    <hr></hr>
    <h2>{`How do we keep it clean?`}</h2>
    <Steps mdxType="Steps">
      <p><strong parentName="p">{`Multi-stage building!`}</strong></p>
      <p>{`One stage compiles all of our various binaries (like HPCG).`}</p>
      <p>{`The other stage (the one with Slurm) gets the binaries to run.`}</p>
    </Steps>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt
    /home/ghost/Downloads/slurm.conf /etc/slurm-llnl/
    /home/ghost/Downloads/cgroup.conf /etc/slurm-llnl/

%environment
    export SOME_VAR=example
    export LC_ALL=C

%post
    apt update && apt install -y munge netcat slurmd slurmctld
    apt install sudo -y && \\
    apt install python3.9 python3-pip -y && \\
    useradd -m admin -s /usr/bin/bash -d /home/admin && \\
    echo "admin:admin" | chpasswd && \\
    adduser admin sudo && \\
    echo "admin     ALL=(ALL) NOPASSWD:ALL" >> /etc/sudoers

%startscript
    sudo sed -i "s/REPLACE_IT/CPUs=$(nproc)/g" /etc/slurm-llnl/slurm.conf
    sudo service munge start
    sudo service slurmctld start # Use ONLY this if controller node
    # sudo slurmd -N $(hostname) # Use ONLY this if worker node

%runscript
    exec "$@"

%test
    grep -q NAME=\\"Ubuntu\\" /etc/os-release
    if [ $? -eq 0 ]; then
        echo "Container base is Ubuntu as expected."
    else
        echo "Container base is not Ubuntu."
    fi

%labels
    Author Absolutely Not My Work (thanks Singularity website)
    Version v0.0.1

`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Add the building stage\" subtitle=\"It's just another header in the definition file.\"",
          "title": "\"Add",
          "the": true,
          "building": true,
          "stage\"": true,
          "subtitle": "\"It's",
          "just": true,
          "another": true,
          "header": true,
          "in": true,
          "definition": true,
          "file.\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: build

Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt
    /home/ghost/Downloads/slurm.conf /etc/slurm-llnl/
    /home/ghost/Downloads/cgroup.conf /etc/slurm-llnl/

%environment
    export SOME_VAR=example
    export LC_ALL=C

%post
    apt update && apt install -y munge netcat slurmd slurmctld
    apt install sudo -y && \\
    apt install python3.9 python3-pip -y && \\
    useradd -m admin -s /usr/bin/bash -d /home/admin && \\
    echo "admin:admin" | chpasswd && \\
    adduser admin sudo && \\
    echo "admin     ALL=(ALL) NOPASSWD:ALL" >> /etc/sudoers

%startscript
    sudo sed -i "s/REPLACE_IT/CPUs=$(nproc)/g" /etc/slurm-llnl/slurm.conf
    sudo service munge start
    sudo service slurmctld start # Use ONLY this if controller node
    # sudo slurmd -N $(hostname) # Use ONLY this if worker node

%runscript
    exec "$@"

%test
    grep -q NAME=\\"Ubuntu\\" /etc/os-release
    if [ $? -eq 0 ]; then
        echo "Container base is Ubuntu as expected."
    else
        echo "Container base is not Ubuntu."
    fi

%labels
    Author Absolutely Not My Work (thanks Singularity website)
    Version v0.0.1


`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Compile HPCG\" subtitle=\"Robbed straight from their website's instructions.\"",
          "title": "\"Compile",
          "HPCG\"": true,
          "subtitle": "\"Robbed",
          "straight": true,
          "from": true,
          "their": true,
          "website's": true,
          "instructions.\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: build

%post
    truncate -s0 /tmp/preseed.cfg; \\
    echo "tzdata tzdata/Areas select Europe" >> /tmp/preseed.cfg; \\
    echo "tzdata tzdata/Zones/Europe select Berlin" >> /tmp/preseed.cfg; \\
    debconf-set-selections /tmp/preseed.cfg && \\
    rm -f /etc/timezone /etc/localtime && \\
    apt-get update && \\
    apt-get install -y tzdata
    apt install -y build-essential mpich libmpich-dev wget
    cd ~
    wget http://www.hpcg-benchmark.org/downloads/hpcg-3.1.tar.gz
    tar -xzvf hpcg-3.1.tar.gz
    cd hpcg-3.1/setup
    cp Make.Linux_MPI Make.linux
    cd ..
    mkdir build
    cd build
    ../configure linux
    make -j $(nproc)
    cd bin && mv xhpcg /

Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt
    /home/ghost/Downloads/slurm.conf /etc/slurm-llnl/
    /home/ghost/Downloads/cgroup.conf /etc/slurm-llnl/

%environment
    export SOME_VAR=example
    export LC_ALL=C

%post
    apt update && apt install -y munge netcat slurmd slurmctld
    apt install sudo -y && \\
    apt install python3.9 python3-pip -y && \\
    useradd -m admin -s /usr/bin/bash -d /home/admin && \\
    echo "admin:admin" | chpasswd && \\
    adduser admin sudo && \\
    echo "admin     ALL=(ALL) NOPASSWD:ALL" >> /etc/sudoers

%startscript
    sudo sed -i "s/REPLACE_IT/CPUs=$(nproc)/g" /etc/slurm-llnl/slurm.conf
    sudo service munge start
    sudo service slurmctld start # Use ONLY this if controller node
    # sudo slurmd -N $(hostname) # Use ONLY this if worker node

%runscript
    exec "$@"

%test
    grep -q NAME=\\"Ubuntu\\" /etc/os-release
    if [ $? -eq 0 ]; then
        echo "Container base is Ubuntu as expected."
    else
        echo "Container base is not Ubuntu."
    fi

%labels
    Author Absolutely Not My Work (thanks Singularity website)
    Version v0.0.1

`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash",
          "metastring": "title=\"Add the binary to the final container\" subtitle=\"Just transfer it with a files section\"",
          "title": "\"Add",
          "the": true,
          "binary": true,
          "to": true,
          "final": true,
          "container\"": true,
          "subtitle": "\"Just",
          "transfer": true,
          "it": true,
          "with": true,
          "a": true,
          "files": true,
          "section\"": true
        }}>{`Bootstrap: library
From: ubuntu:20.04
Stage: build

%post
    truncate -s0 /tmp/preseed.cfg; \\
    echo "tzdata tzdata/Areas select Europe" >> /tmp/preseed.cfg; \\
    echo "tzdata tzdata/Zones/Europe select Berlin" >> /tmp/preseed.cfg; \\
    debconf-set-selections /tmp/preseed.cfg && \\
    rm -f /etc/timezone /etc/localtime && \\
    apt-get update && \\
    apt-get install -y tzdata
    apt install -y build-essential mpich libmpich-dev wget
    cd ~
    wget http://www.hpcg-benchmark.org/downloads/hpcg-3.1.tar.gz
    tar -xzvf hpcg-3.1.tar.gz
    cd hpcg-3.1/setup
    cp Make.Linux_MPI Make.linux
    cd ..
    mkdir build
    cd build
    ../configure linux
    make -j $(nproc)
    cd bin && mv xhpcg /

Bootstrap: library
From: ubuntu:20.04
Stage: final

%setup
    touch /test_file

%files
    /test_file /opt
    /home/ghost/Downloads/slurm.conf /etc/slurm-llnl/
    /home/ghost/Downloads/cgroup.conf /etc/slurm-llnl/

%files from build
    /xhpcg /opt

%environment
    export SOME_VAR=example
    export LC_ALL=C

%post
    apt update && apt install -y munge netcat slurmd slurmctld
    apt install sudo -y && \\
    apt install python3.9 python3-pip -y && \\
    useradd -m admin -s /usr/bin/bash -d /home/admin && \\
    echo "admin:admin" | chpasswd && \\
    adduser admin sudo && \\
    echo "admin     ALL=(ALL) NOPASSWD:ALL" >> /etc/sudoers

%startscript
    sudo sed -i "s/REPLACE_IT/CPUs=$(nproc)/g" /etc/slurm-llnl/slurm.conf
    sudo service munge start
    sudo service slurmctld start # Use ONLY this if controller node
    # sudo slurmd -N $(hostname) # Use ONLY this if worker node

%runscript
    exec "$@"

%test
    grep -q NAME=\\"Ubuntu\\" /etc/os-release
    if [ $? -eq 0 ]; then
        echo "Container base is Ubuntu as expected."
    else
        echo "Container base is not Ubuntu."
    fi

%labels
    Author Absolutely Not My Work (thanks Singularity website)
    Version v0.0.1

`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Tying it all together`}</h1>
    <Steps mdxType="Steps">
      <p>{`Each node will have these containers running.`}</p>
      <p>{`Now they need to connect.`}</p>
    </Steps>
    <hr></hr>
    <h1>{`Some luck!`}</h1>
    <Steps mdxType="Steps">
      <p>{`Containers can be searched via hostname or IP!`}</p>
      <p>{`Singularity doesn't do network isolation.`}</p>
      <p>{`Just `}<inlineCode parentName="p">{`singularity run hpcg-slurm.dif`}</inlineCode>{`!`}</p>
    </Steps>
    <hr></hr>
    <h1>{`What's next?`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul"><strong parentName="li">{`The (Slurm) world is your oyster!`}</strong></li>
        <li parentName="ul">{`Run the HPCG benchmark with `}<inlineCode parentName="li">{`mpirun /opt/xhpcg ...`}</inlineCode>{` `}</li>
        <li parentName="ul">{`Connect with a Jupyter notebook`}</li>
        <li parentName="ul">{`Add more nodes!`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`Questions?`}</h1>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;